<template>
	<div id="site-details">
		<div v-if="!!serverBusy" id="site-details-loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }} Site Details</span>
		</div>
		<div v-if="!serverBusy" class="input-section">
			<fieldset>
				<legend>Edit Casino Details</legend>
				<div class="button-container">
					<button
						id="disable-casino"
						:title="isEnabled ? 'Disable Casino' : 'Enable Casino'"
						class="btn"
						:class="isEnabled ? 'red-bg' : ''"
						@click="enableOrDisableAndSave()"
					>
						Click to {{ isEnabled ? "Disable Casino" : "Enable Casino" }}
					</button>
				</div>
				<div class="input-group">
					<div class="label-input">
						<label for="name" title="Name">Name</label>
						<input
							type="text"
							name="name"
							placeholder="Name"
							title="Name"
							v-model="siteName"
							@input="changed()"
							@keyup.enter="saveSiteDetails()"
						/>
					</div>
					<div class="label-input">
						<label for="description" title="Description">{{ isMobile ? "Descrip" : "Description" }}</label>
						<input
							type="text"
							name="description"
							placeholder="Description"
							title="Description"
							v-model="description"
							@input="changed()"
							@keyup.enter="saveSiteDetails()"
						/>
					</div>
					<div class="label-input">
						<label for="logoUrl" title="Logo Url">Logo Url</label>
						<input
							type="text"
							name="logoUrl"
							placeholder="Logo Url"
							title="Logo Url"
							v-model="logoUrl"
							@input="changed()"
							@keyup.enter="saveSiteDetails()"
						/>
					</div>
					<div class="label-input">
						<label for="websiteUrl" title="Website Url">Website Url</label>
						<input
							type="text"
							name="websiteUrl"
							placeholder="Website Url"
							title="Website Url"
							v-model="websiteUrl"
							@input="changed()"
							@keyup.enter="saveSiteDetails()"
						/>
					</div>
					<div class="label-input">
						<label for="accountLimitAU" title="Account Limit AU">Account Limit AU</label>
						<input
							type="number"
							name="accountLimitAU"
							placeholder="Account Limit AU (required)"
							title="Account Limit AU"
							v-model="accountLimitAU"
							@input="changed()"
							@keyup.enter="saveSiteDetails()"
						/>
					</div>
					<div class="label-input">
						<label for="mruCashOutLimitCOC" title="Mru CashOut Limit">Mru CashOut Limit</label>
						<input
							type="number"
							name="mruCashOutLimitCOC"
							placeholder="Mru CashOut Limit (required)"
							title="Mru CashOut Limit"
							v-model="mruCashOutLimitCOC"
							@input="changed()"
							@keyup.enter="saveSiteDetails()"
						/>
					</div>
					<div class="label-input">
						<label for="startOfBusinessDayHourUtc" title="Start of Business Day Hour (UTC)"
							>Start of {{ isMobile ? "" : "Business " }}Day Hour (UTC)</label
						>
						<input
							type="number"
							name="startOfBusinessDayHourUtc"
							placeholder="Start of Business Day Hour (UTC) (required)"
							title="Start of Business Day Hour (UTC)"
							v-model="startOfBusinessDayHourUtc"
							@input="changed()"
							@keyup.enter="saveSiteDetails()"
						/>
					</div>
					<div class="label-input">
						<label for="lockFundsThresholdAU" title="Lock Funds Threshold AU"
							>Lock Funds {{ isMobile ? "Thresh." : "Threshold" }} AU</label
						>
						<input
							type="number"
							name="lockFundsThresholdAU"
							placeholder="Lock Funds Threshold AU (required)"
							title="Lock Funds Threshold AU"
							v-model="lockFundsThresholdAU"
							@input="changed()"
							@keyup.enter="saveSiteDetails()"
						/>
					</div>

					<div class="label-input">
						<label for="accountCreationBonusPromoAU" title="Account Creation Bonus Promo AU">Account Creation Bonus Promo AU</label>
						<input
							type="number"
							name="accountCreationBonusPromoAU"
							placeholder="Account Creation Bonus Promo AU"
							title="Account Creation Bonus Promo AU"
							v-model="accountCreationBonusPromoAU"
							@input="changed()"
							@keyup.enter="saveSiteDetails()"
						/>
					</div>

					<div class="label-input">
						<label for="adminNotes" title="Admin Notes">Admin Notes (Remaining: {{ charactersRemaining }})</label>
						<textarea :maxlength="maxCharacters" name="adminNotes" title="Admin Notes" v-model="adminNotes" @input="changed()"></textarea>
					</div>
					<div class="label-input wrap">
						<div class="playable-label">
							<label for="playableLocations" title="Playable Locations.  Locations must be comma separated.">{{
								isMobile ? "Play Loc." : "Playable Locations"
							}}</label>
							<small class="location-note">Note: Locations must be comma separated.</small>
						</div>
						<input
							type="text"
							name="playableLocations"
							:placeholder="`No location restrictions set for ${adminState?.casinoName}`"
							title="Playable Locations. Locations must be comma separated."
							v-model="playableLocations"
							@input="changed()"
							@keyup.enter="saveSiteDetails()"
						/>
						<label for="bingoCardPolicy" title="Bingo Card Policy">Bingo Card Policy</label>
						<select name="bingoCardPolicy" title="Bingo Card Policy" id="bingoCardPolicy" v-model="bingoCardPolicy" @input="changed()">
							<option v-for="option in bingoCardPolicyOptions" :key="option" :value="option">
								{{ option }}
							</option>
						</select>
					</div>
					<div class="currency-display-settings">
						<h3>Currency Display Settings</h3>
						<div class="label-input">
							<label for="minor-digits" title="Number of minor digits in currency unit">Number of minor digits in currency unit</label>
							<input
								type="number"
								name="minor-digits"
								placeholder="Number of minor digits (required)"
								title="Number of minor digits in currency unit"
								v-model="minorDigits"
								@input="changed()"
								@keyup.enter="saveSiteDetails()"
							/>
						</div>
						<div class="label-input">
							<label for="currency-unit" title="Currency Unit">Number of accounting units (AU) for each currency unit</label>
							<input
								type="number"
								name="currency-unit"
								placeholder="Currency Unit (required)"
								title="Currency Unit"
								v-model="auPerCurrencyUnit"
								@input="changed()"
								@keyup.enter="saveSiteDetails()"
							/>
						</div>
						<div class="label-input">
							<label for="name-plural" title="Plural form of currency's name">Currency Name Plural</label>
							<input
								type="text"
								name="name-plural"
								placeholder="Name Plural"
								title="Plural form of currency's name"
								v-model="namePlural"
								@input="changed()"
								@keyup.enter="saveSiteDetails()"
							/>
						</div>
						<div class="label-input">
							<label for="name-singular" title="Singular form of currency's name">Currency Name Singular</label>
							<input
								type="text"
								name="name-singular"
								placeholder="Name Singular"
								title="Singular form of currency's name"
								v-model="nameSingular"
								@input="changed()"
								@keyup.enter="saveSiteDetails()"
							/>
						</div>

						<div class="label-input">
							<label for="minor-name-plural" title="Plural form of currency's minor name">Currency Minor Name Plural</label>
							<input
								type="text"
								name="minor-name-plural"
								placeholder="Minor Name Plural"
								title="Plural form of currency's minor name"
								v-model="minorNamePlural"
								@input="changed()"
								@keyup.enter="saveSiteDetails()"
							/>
						</div>
						<div class="label-input">
							<label for="minor-name-singular" title="Singular form of currency's minor name">Currency Minor Name Singular</label>
							<input
								type="text"
								name="minor-name-singular"
								placeholder="Minor Name Singular"
								title="Singular form of currency's minor name"
								v-model="minorNameSingular"
								@input="changed()"
								@keyup.enter="saveSiteDetails()"
							/>
						</div>

						<div class="label-input symbol">
							<div>
								<label for="symbol" title="Symbols that might be relevant for showing this currency">In-Game Symbol</label>
								<input
									type="text"
									name="symbol"
									placeholder="In-Game Symbol"
									title="Symbols that might be relevant for showing this currency in-game"
									v-model="symbol"
									@input="changed()"
									@keyup.enter="saveSiteDetails()"
								/>
							</div>
							<div>
								<label
									for="symbol-in-front"
									title="Insert the currency symbol at the front of the currency value"
									class="symbol-in-front"
									>Insert Symbol In Front of Value</label
								>
								<input
									id="symbol-in-front"
									type="checkbox"
									name="symbol-in-front"
									title="Insert the currency symbol at the front of the currency value"
									v-model="doesSymbolGoInFront"
									@input="changed()"
								/>
							</div>
							<div>
								<label for="minorSymbol" title="Minor symbol that might be relevant for showing this currency"
									>In-Game Minor Symbol</label
								>
								<input
									type="text"
									name="minorSymbol"
									placeholder="Minor Symbol"
									title="Minor symbol that might be relevant for showing this currency in-game "
									v-model="minorSymbol"
									@input="changed()"
									@keyup.enter="saveSiteDetails()"
								/>
							</div>
							<div>
								<label
									for="doesMinorSymbolGoInFront"
									title="Insert the minor symbol symbol at the front of the currency value"
									class="doesMinorSymbolGoInFront"
									>Insert Minor Symbol In Front of Value</label
								>
								<input
									id="doesMinorSymbolGoInFront"
									type="checkbox"
									name="doesMinorSymbolGoInFront"
									title="Insert the minor symbol symbol at the front of the currency value"
									v-model="doesMinorSymbolGoInFront"
									@input="changed()"
								/>
							</div>

							<div>
								<label for="spaceBetweenSymbol" title="Insert space between symbol and the currency value" class="spaceBetweenSymbol"
									>Insert space between symbol and currency</label
								>
								<input
									id="spaceBetweenSymbol"
									type="checkbox"
									name="spaceBetweenSymbol"
									title="Insert space between symbol and the currency value"
									v-model="spaceBetweenSymbol"
									@input="changed()"
								/>
							</div>

							<div>
								<label for="iso4217Code" title="ISO 4217 Code">ISO 4217 Code</label>
								<input
									type="text"
									name="iso4217Code"
									placeholder="ISO 4217 Code"
									title="ISO 4217 Code"
									v-model="iso4217Code"
									@input="changed()"
									@keyup.enter="saveSiteDetails()"
								/>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
			<div class="button-group" v-if="fieldsChanged">
				<button class="btn" type="button" @click="saveSiteDetails()" title="Save Site Details">Save Site Details</button>
				<button class="btn" type="button" @click="cancelEditSiteDetails()" title="Undo All Changes">Undo All Changes</button>
			</div>
		</div>
	</div>
</template>

<script>
import store from "@/store/index";
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "EditCasinoDetails",
	props: {
		adminState: Object,
		casinoList: Array,
		isMobile: Boolean,
		systemCurrencyTool: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			fieldsChanged: false,
			busyText: "",
			siteName: "",
			isEnabled: false,
			description: "",
			logoUrl: "",
			websiteUrl: "",
			startOfBusinessDayHourUtc: null,
			lockFundsThresholdAU: null,
			playableLocations: [],
			mruCashOutLimitCOC: "",
			accountLimitAU: "",
			casinoInfo: this.casinoList?.[this.adminState.casinoId - 1],
			nameSingular: "",
			namePlural: "",
			symbol: "",
			iso4217Code: "",
			doesSymbolGoInFront: Boolean,
			auPerCurrencyUnit: Number,
			spaceBetweenSymbol: Boolean,
			bingoCardPolicy: "",
			minorDigits: Number,
			minorNameSingular: "",
			minorNamePlural: "",
			minorSymbol: "",
			doesMinorSymbolGoInFront: Boolean,
			bingoCardPolicyOptions: ["Show", "Hide"],
			adminNotes: "",
			maxCharacters: 512,
			charactersRemaining: this.maxCharacters,
			accountCreationBonusPromoAU: 0,
			currentModal: null,
		};
	},
	watch: {
		adminNotes() {
			this.getRemainingCount();
		},
	},
	created() {
		this.getSiteDetails();
		this.eventBus.on("resetEditCasinoDetails", () => {
			this.cancelEditSiteDetails();
		});
		onBeforeUnmount(() => {
			this.cancelEditSiteDetails();
			this.eventBus.off("resetEditCasinoDetails");
		});
	},
	mounted() {
		this.getRemainingCount();
	},
	methods: {
		getRemainingCount() {
			this.charactersRemaining = this.maxCharacters - this.adminNotes.length;
		},
		enableOrDisableAndSave() {
			if (this.isEnabled) {
				let confirmDisable = confirm(`Are you sure you want to disable ${this.siteName}`);
				if (!confirmDisable) return false;
			}
			this.isEnabled = this.isEnabled ? false : true;
			this.saveSiteDetails();
		},
		changed() {
			// We need this timeout to slow down the Vue renderer from updating this property.
			// Seems to only be a Chrome issue. Other browsers even other Chromium based ones don't have this issue.
			// Without this, the bingoCardPolicy dropdown would have to be selected twice.
			// I don't know why it rerenders with it's original value.
			// From my testing, a few millisecs do the trick but I set 300ms just to be sure for
			// different environments and that's a delay nobody would likely care about.
			setTimeout(() => {
				this.fieldsChanged = true;
			}, 300);
		},
		setCurrentSiteDetailVariables(siteDetails) {
			this.siteName = siteDetails.name;
			this.isEnabled = siteDetails.isEnabled;
			this.description = siteDetails.description;
			this.logoUrl = siteDetails.logoUrl;
			this.websiteUrl = siteDetails.websiteUrl;
			this.startOfBusinessDayHourUtc = siteDetails.startOfBusinessDayHourUtc;
			this.lockFundsThresholdAU = siteDetails.lockFundsThresholdAU;
			this.playableLocations = siteDetails.playableLocations;
			this.mruCashOutLimitCOC = siteDetails.mruCashOutLimitCOC;
			this.accountLimitAU = siteDetails.accountLimitAU;
			this.bingoCardPolicy = siteDetails.gameSettings.bingoCardPolicy;
			this.adminNotes = siteDetails.adminNotes;
			this.iso4217Code = siteDetails.currencyInfo.iso4217Code;
			this.nameSingular = siteDetails.currencyInfo.nameSingular;
			this.namePlural = siteDetails.currencyInfo.namePlural;
			this.symbol = siteDetails.currencyInfo.symbol;
			this.doesSymbolGoInFront = siteDetails.currencyInfo.doesSymbolGoInFront;
			this.spaceBetweenSymbol = siteDetails.currencyInfo.spaceBetweenSymbol;
			this.auPerCurrencyUnit = siteDetails.currencyInfo.auPerCurrencyUnit;
			this.minorDigits = siteDetails.currencyInfo.minorDigits;
			this.minorNameSingular = siteDetails.currencyInfo.minorNameSingular;
			this.minorNamePlural = siteDetails.currencyInfo.minorNamePlural;
			this.minorSymbol = siteDetails.currencyInfo.minorSymbol;
			this.doesMinorSymbolGoInFront = siteDetails.currencyInfo.doesMinorSymbolGoInFront;
			this.accountCreationBonusPromoAU = siteDetails.accountCreationBonusPromoAU;
		},
		cancelEditSiteDetails() {
			if (this.fieldsChanged) {
				this.setCurrentSiteDetailVariables(store.state.casinoBasicDetails);
				this.fieldsChanged = false;
			}
		},
		async saveSiteDetails() {
			this.busyText = "Saving";
			this.serverBusy = true;
			this.status.ok = true;

			// preflight validation of site details fields
			// Rabbitsfoot server does not require Name but for practical reasons we are forcing
			// the user to input a Name. There is no check for uniqueness of the Name.
			if (!this.siteName) {
				this.status.message = "Name is a required field. Please name this casino.";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
			}

			if (!this.accountLimitAU > 0) {
				this.status.message = "Account limit must be a number greater than 0";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
			}

			if (!this.mruCashOutLimitCOC > 0) {
				this.status.message = "MRU cash out limit must be a number greater than 0";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
			}

			if (!this.lockFundsThresholdAU > 0) {
				this.status.message = "Lock funds threshold must be a number greater than 0";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
			}

			if (!Number.isInteger(this.startOfBusinessDayHourUtc) || this.startOfBusinessDayHourUtc < 0 || this.startOfBusinessDayHourUtc > 23) {
				this.status.message = "Start of business day must be a number from 0 to 23";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
			}

			if (!Number.isInteger(this.auPerCurrencyUnit) || this.auPerCurrencyUnit <= 0) {
				this.status.message = "Currency Unit must be a number greater than 0";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
			}

			if (!Number.isInteger(this.minorDigits) || this.minorDigits < 0) {
				this.status.message = "Minor Digits must be a number which is not less than 0";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
			}

			if (this.status.ok === false) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			if (!Array.isArray(this.playableLocations)) this.playableLocations = this.playableLocations.toUpperCase().replace(/\s/g, "").split(",");
			let body = {
				id: this.adminState.casinoId,
				name: this.siteName,
				isEnabled: this.isEnabled,
				description: this.description,
				gameSettings: {
					bingoCardPolicy: this.bingoCardPolicy,
				},
				logoUrl: this.logoUrl,
				websiteUrl: this.websiteUrl,
				startOfBusinessDayHourUtc: this.startOfBusinessDayHourUtc,
				lockFundsThresholdAU: this.lockFundsThresholdAU,
				playableLocations: this.playableLocations,
				mruCashOutLimitCOC: this.mruCashOutLimitCOC,
				accountLimitAU: this.accountLimitAU,
				currencyInfo: {
					iso4217Code: this.iso4217Code,
					nameSingular: this.nameSingular,
					namePlural: this.namePlural,
					symbol: this.symbol,
					doesSymbolGoInFront: this.doesSymbolGoInFront,
					spaceBetweenSymbol: this.spaceBetweenSymbol,
					auPerCurrencyUnit: this.auPerCurrencyUnit,
					minorDigits: this.minorDigits,
					minorNameSingular: this.minorNameSingular,
					minorNamePlural: this.minorNamePlural,
					minorSymbol: this.minorSymbol,
					doesMinorSymbolGoInFront: this.doesMinorSymbolGoInFront,
				},
				adminNotes: this.adminNotes,
				accountCreationBonusPromoAU: this.accountCreationBonusPromoAU ? this.accountCreationBonusPromoAU : 0,
			};

			let requestUrl = new URL("/api/v1/site/settings", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.busyText = "";
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				// replace cache of site details in case of cancelling or switching tabs
				store.dispatch("setCasinoBasicDetails", dataJson);
				this.setCurrentSiteDetailVariables(dataJson);

				let _state = this.adminState;
				_state.currencyInfo = dataJson.currencyInfo;
				this.eventBus.emit("updateAdminState", _state);

				this.fieldsChanged = false;
				this.status.message = `${this.siteName} site details saved successfully`;
				this.status.code = fetchStatus.code;
				this.status.ok = fetchStatus.ok;
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("updateCasinoList");
				if (this.adminState?.Sysadmin) this.eventBus.emit("updateCasinosIncludingDeleted");
				this.eventBus.emit("resetCurrencyToolConstructor");
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
				return false;
			}
		},
		async getSiteDetails() {
			this.busyText = "Loading";
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let requestUrl = new URL(`/api/v1/site/settings/${this.adminState.casinoId}`, this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.adminState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				// cache current site details in case of cancelling or switching tabs
				store.dispatch("setCasinoBasicDetails", dataJson);

				this.setCurrentSiteDetailVariables(dataJson);

				this.serverBusy = false;
				this.fieldsChanged = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* #site-details, */
#site-details-loading {
	display: grid;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 30px;
	z-index: 9999;
	transition: background-color 0.3s ease-in-out;
}

#site-details-loading {
	text-align: center;
	align-content: center;
}

.loading-message {
	margin-top: 15px;
}

#disable-casino {
	display: inline-block;
	width: 15em;
	padding: 8px;
	margin: auto auto 10px;
	text-transform: uppercase;
}

.input-section {
	padding: 0 0 30px;
}

.input-section fieldset {
	display: flex;
	flex-flow: column nowrap;
	padding: 0;
	margin: 0;
	min-width: 0;
}

.button-group {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	margin-top: 10px;
}

.button-container {
	margin: auto;
}

.saving-error {
	margin: 0 10px;
	color: #f00;
	text-align: center;
	text-transform: uppercase;
	background-color: #000;
}

.location-note {
	margin-left: 10px;
	color: #41bd02;
	font-weight: bold;
}

.input-group {
	margin: auto;
	width: 100%;
	min-width: 10em;
}

.label-input {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
}

.label-input label,
.input-group .playable-label {
	max-width: 10em;
	flex: 0 1 auto;
	text-align: right;
}

.input-group input {
	flex: 0 1 auto;
}

textarea {
	flex: 1 1 auto;
}

.label-input.symbol {
	display: grid;
}

label[for="bingoCardPolicy"] {
	align-self: center;
}

.symbol-in-front,
#symbol-in-front {
	cursor: pointer;
}

.playable-label {
	display: flex;
	flex-direction: column;
	margin: 5px 10px;
}

.wrap {
	flex-wrap: wrap;
}

@media (min-width: 768px) {
	.input-section fieldset {
		padding: 0.75em;
	}

	.label-input label,
	.input-group .playable-label {
		flex: 1 1 auto;
	}

	.input-group input {
		flex: 1 1 50%;
	}

	.button-group {
		justify-content: flex-end;
	}

	.wrap {
		flex-wrap: nowrap;
	}
}
</style>
